@import 'spotme-icons.css';
@import 'loader.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

.details-mobile-navbar {
  position: absolute;
  z-index: 10000;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
/* Utilities */
.scrollbar-hide {
  -ms-overflow-style: none !important;
  scrollbar-width: none !important;
}

.scrollbar-hide::-webkit-scrollbar {
  display: none !important;
}

/* Search Styles */
/* - Filter Styles */
.MuiFormGroup-root .radio-container {
  border-color: theme('colors.grey.300');
  background-color: theme('colors.grey.300');
}

.MuiFormGroup-root .radio-container .si {
  color: theme('colors.grey.500');
}

.MuiFormGroup-root .radio-container:has(.Mui-checked.MuiRadio-root) {
  border-color: theme('colors.blue.500');
  background-color: white;
  color: theme('colors.blue.500');
}

.MuiFormGroup-root .radio-container:has(.Mui-checked.MuiRadio-root) .si {
  color: theme('colors.blue.500');
}

/*    - Quick filters */
.quick-filters .MuiFormControlLabel-root {
  border-color: theme('colors.grey.300');
  background-color: theme('colors.grey.300');
}

.quick-filters .MuiFormControlLabel-root .si {
  color: theme('colors.grey.500');
}

.quick-filters .MuiFormControlLabel-root:has(.Mui-checked) {
  border-color: theme('colors.blue.500');
  background-color: white;
  color: theme('colors.blue.500');
}

.quick-filters .MuiFormControlLabel-root:has(.Mui-checked) .si {
  color: theme('colors.blue.500');
}

/* - Location Card */
.location-search-card a {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 1;
}

@layer utilities {
  .customMUITextField {
    @apply mt-[27px];
  }

  .customMUITextField .MuiInputBase-root {
    @apply rounded-[10px] bg-grey-300 !important;
  }
  .customMUITextField .MuiFilledInput-root {
    @apply opacity-100 placeholder:bg-black-300 before:border-0 after:border-0 hover:border-0 !important;
  }

  .customMUITextField
    label
    + .MuiInputBase-formControl
    .MuiInputBase-input.MuiFilledInput-input {
    @apply px-[14px] py-[10px] placeholder:text-black-300 placeholder:opacity-100 !important;
  }

  .customMUITextField .MuiInputLabel-root {
    @apply left-0 top-[-14px] translate-x-[0] translate-y-[-50%] text-[15px] font-semibold text-black-400;
  }

  .scanner::before {
    @apply absolute bottom-0 left-0 top-0 z-20 w-[5px] bg-green-500 shadow-[0_0_70px_20px] shadow-green-500 content-[""];
    clip-path: inset(0);
    animation:
      x 1s ease-in-out infinite alternate,
      y 2s ease-in-out infinite;
  }
  @keyframes x {
    to {
      transform: translateX(-100%);
      left: 100%;
    }
  }

  @keyframes y {
    33% {
      clip-path: inset(0 0 0 -100px);
    }
    50% {
      clip-path: inset(0 0 0 0);
    }
    83% {
      clip-path: inset(0 -100px 0 0);
    }
  }
}

/*----------------Footer-----------------*/
@media (max-width: 769px) {
  .footer-mobile-layout {
    flex-direction: column;
  }
  .footer-section {
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
  }
  .footer-section:nth-child(odd) {
    order: 2;
  }
  .footer-section:nth-child(even) {
    order: 1;
  }
}

.gm-style iframe + div {
  border: none !important;
}

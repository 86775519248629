@font-face {
  font-family: 'spotme-icons';
  src: url('/fonts/spotme-icons/spotme-icons.eot?cd38h2');
  src:
    url('/fonts/spotme-icons/spotme-icons.eot?cd38h2#iefix')
      format('embedded-opentype'),
    url('/fonts/spotme-icons/spotme-icons.ttf?cd38h2') format('truetype'),
    url('/fonts/spotme-icons/spotme-icons.woff?cd38h2') format('woff'),
    url('/fonts/spotme-icons/spotme-icons.svg?cd38h2#spotme-icons')
      format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.si {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'spotme-icons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.si-user-headset:before {
  content: '\e976';
}
.si-addSpot:before {
  content: '\e96c';
}
.si-bankAccount:before {
  content: '\e96d';
}
.si-check:before {
  content: '\e96e';
}
.si-hobbie:before {
  content: '\e96f';
}
.si-job:before {
  content: '\e970';
}
.si-language:before {
  content: '\e971';
}
.si-movie:before {
  content: '\e972';
}
.si-profileConfirmation:before {
  content: '\e973';
}
.si-socialnetwork:before {
  content: '\e974';
}
.si-song:before {
  content: '\e975';
}
.si-add-circle:before {
  content: '\e944';
}
.si-air-conditioning:before {
  content: '\e945';
}
.si-alarms:before {
  content: '\e942';
}
.si-arrow-dropdown:before {
  content: '\e92f';
}
.si-arrow-left:before {
  content: '\e955';
}
.si-arrow-left1:before {
  content: '\e935';
}
.si-ask:before {
  content: '\e91b';
}
.si-attic:before {
  content: '\e93f';
}
.si-badge-dollar:before {
  content: '\e960';
}
.si-barcode:before {
  content: '\e904';
}
.si-basement:before {
  content: '\e93e';
}
.si-bathroom:before {
  content: '\e938';
}
.si-bed:before {
  content: '\e946';
}
.si-bill:before {
  content: '\e916';
}
.si-box:before {
  content: '\e901';
}
.si-broom:before {
  content: '\e957';
}
.si-bulb:before {
  content: '\e947';
}
.si-camera:before {
  content: '\e95c';
}
.si-camera1:before {
  content: '\e928';
}
.si-caret-down:before {
  content: '\e961';
}
.si-caret-up:before {
  content: '\e962';
}
.si-chat:before {
  content: '\e908';
}
.si-check-fill:before {
  content: '\e927';
}
.si-circle:before {
  content: '\e900';
}
.si-circle-check:before {
  content: '\e95e';
}
.si-circle-cross:before {
  content: '\e924';
}
.si-circle-xmark:before {
  content: '\e95f';
}
.si-cistern:before {
  content: '\e941';
}
.si-close:before {
  content: '\e925';
}
.si-commercial:before {
  content: '\e954';
}
.si-container:before {
  content: '\e931';
}
.si-cricle-check:before {
  content: '\e926';
}
.si-dashboard:before {
  content: '\e952';
}
.si-delivery-truck:before {
  content: '\e903';
}
.si-discount:before {
  content: '\e919';
}
.si-door-closed:before {
  content: '\e959';
}
.si-down:before {
  content: '\e920';
}
.si-edit:before {
  content: '\e933';
}
.si-elevator:before {
  content: '\e92b';
}
.si-entrance:before {
  content: '\e937';
}
.si-eye:before {
  content: '\e90d';
}
.si-facebook:before {
  content: '\e910';
}
.si-fast-delivery:before {
  content: '\e906';
}
.si-fire-alarm:before {
  content: '\e948';
}
.si-garage:before {
  content: '\e93c';
}
.si-gas:before {
  content: '\e943';
}
.si-grounds:before {
  content: '\e930';
}
.si-hours:before {
  content: '\e907';
}
.si-house:before {
  content: '\e932';
}
.si-house-engine:before {
  content: '\e949';
}
.si-house-plus:before {
  content: '\e967';
}
.si-house-shield:before {
  content: '\e94a';
}
.si-identificator:before {
  content: '\e94b';
}
.si-image:before {
  content: '\e95b';
}
.si-images:before {
  content: '\e958';
}
.si-industrial-ship:before {
  content: '\e92e';
}
.si-instagram:before {
  content: '\e91d';
}
.si-key-button:before {
  content: '\e90a';
}
.si-light:before {
  content: '\e939';
}
.si-linkedin:before {
  content: '\e91f';
}
.si-location:before {
  content: '\e902';
}
.si-location-dot:before {
  content: '\e964';
}
.si-lock:before {
  content: '\e90e';
}
.si-magnifying-glass:before {
  content: '\e963';
}
.si-meeting-room:before {
  content: '\e94c';
}
.si-menu-horizontal:before {
  content: '\e90b';
}
.si-menu-vertical:before {
  content: '\e90c';
}
.si-mezzanine:before {
  content: '\e93d';
}
.si-minus:before {
  content: '\e968';
}
.si-minus-circle:before {
  content: '\e94d';
}
.si-money:before {
  content: '\e915';
}
.si-my-rentals:before {
  content: '\e96b';
}
.si-offert:before {
  content: '\e914';
}
.si-paper-plane:before {
  content: '\e934';
}
.si-payment:before {
  content: '\e91a';
}
.si-plus:before {
  content: '\e969';
}
.si-print:before {
  content: '\e923';
}
.si-privacy:before {
  content: '\e940';
}
.si-promo:before {
  content: '\e918';
}
.si-reservations:before {
  content: '\e96a';
}
.si-residencial:before {
  content: '\e92d';
}
.si-residential:before {
  content: '\e953';
}
.si-retail-space:before {
  content: '\e93b';
}
.si-right:before {
  content: '\e921';
}
.si-room-on-lightbulb-on:before {
  content: '\e95a';
}
.si-ruler-combined:before {
  content: '\e95d';
}
.si-sale:before {
  content: '\e917';
}
.si-scanning:before {
  content: '\e905';
}
.si-signal:before {
  content: '\e94e';
}
.si-sliders:before {
  content: '\e966';
}
.si-sort-vertical:before {
  content: '\e929';
}
.si-stairs:before {
  content: '\e92c';
}
.si-star:before {
  content: '\e90f';
}
.si-stop:before {
  content: '\e94f';
}
.si-store:before {
  content: '\e911';
}
.si-temperature-control:before {
  content: '\e93a';
}
.si-thumbs-down:before {
  content: '\e913';
}
.si-thumbs-up:before {
  content: '\e912';
}
.si-tiktok:before {
  content: '\e922';
}
.si-trash:before {
  content: '\e956';
}
.si-trash1:before {
  content: '\e936';
}
.si-user:before {
  content: '\e951';
}
.si-user-single:before {
  content: '\e909';
}
.si-vehicle-entrance:before {
  content: '\e950';
}
.si-warehouse:before {
  content: '\e965';
}
.si-warehouse1:before {
  content: '\e91c';
}
.si-water:before {
  content: '\e92a';
}
.si-youtube:before {
  content: '\e91e';
}
